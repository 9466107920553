import { EMarkVariant } from '../Mark/types';

export const headerConfig = [
	{
		type: EMarkVariant.RED,
		title: 'Выбранный павильон',
	},
	{
		type: EMarkVariant.BLACK,
		title: 'Другие павильоны',
	},
];

export const bounds = (scale: number) =>
	({
		0.5: {
			right: -915,
			bottom: -530,
			left: -2030,
			top: -930,
		},
		0.6: {
			right: -735,
			bottom: -420,
			left: -2210,
			top: -1040,
		},
		0.7: {
			right: -550,
			bottom: -320,
			left: -2390,
			top: -1140,
		},
		0.8: {
			right: -370,
			bottom: -215,
			left: -2580,
			top: -1250,
		},
		0.9: {
			right: -185,
			bottom: -100,
			left: -2750,
			top: -1340,
		},
		1: {
			right: 50,
			bottom: 100,
			left: -3000,
			top: -1500,
		},
		1.1: {
			right: 180,
			bottom: 110,
			left: -3130,
			top: -1570,
		},
		1.2: {
			right: 360,
			bottom: 220,
			left: -3300,
			top: -1700,
		},
		1.3: {
			right: 550,
			bottom: 320,
			left: -3480,
			top: -1800,
		},
		1.4: {
			right: 730,
			bottom: 430,
			left: -3660,
			top: -1910,
		},
		1.5: {
			right: 920,
			bottom: 540,
			left: -3840,
			top: -2020,
		},
	}[scale]);

export const defaultPosition = { x: -1336, y: -755 };
