import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useFormik } from 'formik';
import { OutputData } from '@editorjs/editorjs';

import { useStore } from 'hooks/useStore';
import { CustomPage } from 'components/Layouts';
import { Editor, Loader, TextField, Validation } from 'components/UI';
import Map from '../components/Map/Map';
import PageButtons from './PageButtons/PageButtons';
import AsyncSelectBreadCrumbs from 'components/Logic/AsyncSelect/AsyncSelectBreadCrumbs';

import { serializeEmployers, serializeEvents } from './utils';

import { TEditorType } from 'interfaces/entities/IEditor';
import { TMarkCoords } from '../components/Mark/types';

import styles from './styles.module.scss';

const PavilionsOstrovaUpdate: FC = observer(() => {
	const pavilionsStore = useStore().pavilionsOstrova;
	const item = pavilionsStore.item;

	const [isLoading, setIsLoading] = useState(true);

	const { id } = useParams();
	const backLink = location.pathname.split('/').slice(0, -2).join('/') + location.search;

	useEffect(() => {
		(async function () {
			setIsLoading(true);
			await pavilionsStore.fetchItems('');
			await pavilionsStore.fetchItem(id);
			setIsLoading(false);
		})();
	}, [id]);

	const formik = useFormik({
		initialValues: {
			name: item?.name ?? '',
			description: item?.description ? JSON.parse(item.description) : (null as null | OutputData),
			events: item?.events ?? [],
			x: item?.x ? parseInt(item.x) : (null as number | null),
			y: item?.y ? parseInt(item.y) : (null as number | null),
		},
		enableReinitialize: true,
		onSubmit: async (values) => {
			return pavilionsStore
				.updateItem({
					id: item.id,
					name: values.name,
					description: values.description ? JSON.stringify(values.description) : null,
					event_ids: values.events.map((item) => item.id),
					x: typeof values.x === 'number' ? values.x.toString() : null,
					y: typeof values.y === 'number' ? values.y.toString() : null,
				})
				.catch((data) => {
					const errors = data.errors;

					if ('calendar_event_ids' in errors) errors.calendar_events = errors.calendar_event_ids;
					if ('employer_profile_ids' in errors)
						errors.employer_profiles = errors.employer_profile_ids;

					formik.setErrors(data.errors);
					throw errors;
				});
		},
	});

	const onChangeCurrentMark = (coords: TMarkCoords | null) => {
		formik.setFieldValue('x', coords ? coords.x : null);
		formik.setFieldValue('y', coords ? coords.y : null);
	};

	const currentMark: TMarkCoords | null =
		formik.values.x && formik.values.y ? { x: formik.values.x, y: formik.values.y } : null;

	const mapValidationError =
		formik.errors.y || formik.errors.x ? 'Положение на карте не может быть пустым.' : null;

	if (isLoading) {
		return (
			<CustomPage title='Место проведения / Изменение' backLink={backLink}>
				<Loader />
			</CustomPage>
		);
	}

	return (
		<CustomPage
			title='Место проведения / Изменение'
			backLink={backLink}
			bottomButtons={[<PageButtons formik={formik} type='update' key='buttons' />]}
		>
			<form onSubmit={formik.handleSubmit} className={styles.wrapper}>
				<div className={styles.item}>
					<TextField
						id='name'
						label='Название'
						value={formik.values.name}
						onChange={formik.handleChange}
					/>
					<Validation errors={formik.errors.name} />
				</div>


				<div className={styles.item}>
					<AsyncSelectBreadCrumbs
						extra='Мероприятие может быть недоступно для выбора, если уже связано с другим павильоном'
						name='events'
						type='events'
						label='Прикрепить мероприятие'
						placeholder='Выберите из списка'
						qualities={formik.values.events}
						formikSetter={(items) => formik.setFieldValue('events', items)}
					/>
					<Validation errors={formik.errors.events} />
				</div>

				<div className={styles.mapWrapper}>
					<Map
						label='Положение на карте'
						currentMark={currentMark}
						setCurrentMark={onChangeCurrentMark}
					/>
					<Validation errors={mapValidationError} />
				</div>

				{item?.description && (
					<div className={styles.editorWrapper}>
						<Editor
							label='Контент'
							tools={['list', 'image']}
							editorType={'event_place_wysiwyg' as TEditorType}
							value={formik.values.description}
							onChange={(data) => formik.setFieldValue('description', data)}
						/>
						<Validation errors={formik.errors.description} />
					</div>
				)}
			</form>
		</CustomPage>
	);
});

export default PavilionsOstrovaUpdate;
