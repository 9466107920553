import {FC, useEffect, useState} from 'react';
import { useParams } from 'react-router';
import { observer } from 'mobx-react-lite';
import {useFormik} from 'formik';

import { useStore } from 'hooks/useStore';
import {CustomPage} from 'components/Layouts';
import {DatePicker, Loader, Select, TextField, Validation} from 'components/UI';
import {PhoneField} from '../../components/Form';
import AsyncSelect from 'components/Logic/AsyncSelect/AsyncSelect/AsyncSelect';

import PageButtons from './components/PageButtons';

import {Courses, practiceTypes, professionalEducationTypes} from './types';
import {IOption} from '../../components/UI/Select/types';

import styles from '../Pavilions/PavilionCU/styles.module.scss';

const PracticeStudentUpdate: FC = observer(() => {
	const store = useStore();
	const { id } = useParams();
	const item = store.practiceStudents.element;

	const [isLoading, setIsLoading] = useState(true);

	const backLink = location.pathname.split('/').slice(0, -2).join('/') + location.search;

	if (id === undefined) {
		return null;
	}

	useEffect(() => {
		(async function () {
			setIsLoading(true);
			await store.practiceStudents.fetchItems('');
			await store.practiceStudents.fetchItem(id);
			setIsLoading(false);
		})();
	}, [id]);
	const formik = useFormik({
		initialValues: {
			speciality_id: item.speciality?.id?.toString(),
			speciality_name: item.speciality?.name?.toString(),
			institution_name: item.institution_name,
			start_date: item.start_date,
			finish_date: item.finish_date,
			course: item.course,
			student_count: item.student_count,
			practice_type: item.practice_type,
			full_name_of_respondent: item.full_name_of_respondent,
			phone_of_respondent: item.phone_of_respondent,
			email_of_respondent: item.email_of_respondent,
			professional_education_type: item.professional_education_type,
			city: item.city
		},
		enableReinitialize: true,
		onSubmit: async (values) => {
			return store.practiceStudents
				.updateItem({
					id: +id,
					speciality_id: +values.speciality_id,
					institution_name: values.institution_name,
					start_date: values.start_date,
					finish_date: values.finish_date,
					course: values.course,
					student_count: values.student_count,
					practice_type: values.practice_type,
					full_name_of_respondent: values.full_name_of_respondent,
					phone_of_respondent: values.phone_of_respondent,
					email_of_respondent: values.email_of_respondent.toLowerCase(),
					professional_education_type: values.professional_education_type,
					city: values.city
				})
				.catch((data) => {
					const errors = data.errors;

					if ('calendar_event_ids' in errors) errors.calendar_events = errors.calendar_event_ids;
					if ('employer_profile_ids' in errors)
						errors.employer_profiles = errors.employer_profile_ids;

					formik.setErrors(data.errors);
					throw errors;
				});
		}
	});

	const onSpecialityChange = (option: IOption) => {
		formik.setFieldValue('speciality_name',option?.label);
		formik.setFieldValue('speciality_id',option?.value);

	};

	const pageButtons = <PageButtons formik={formik} type={'update'}/>;

	if (isLoading) {
		return (
			<CustomPage title='Павильоны / Изменение' backLink={backLink}>
				<Loader />
			</CustomPage>
		);
	}

	return (
		<CustomPage title="График практик студентов / Изменение" bottomButtons={[pageButtons]} backLink={backLink}>
			<form onSubmit={formik.handleSubmit} className={styles.wrapper}>
				<div className={styles.item}>
					<AsyncSelect
						label={'Специальность'}
						type={'specialities'}
						name={'speciality_id'}
						selectValue={{value: formik.values.speciality_id, label: formik.values.speciality_name}}
						onSelectChange={(option) =>onSpecialityChange(option ?? {value: 1, label: ''})}/>
					<Validation errors={formik.errors.speciality_id} />
				</div>
				<div className={styles.item}>
					<Select
						id={'course'}
						label={'Уровень образования'}
						value={professionalEducationTypes.find((option) => option.value === formik.values.professional_education_type)}
						onChange={(option) => {
							console.log(option);
							formik.setFieldValue('professional_education_type', option?.value);
						}}
						placeholder="Выберите из списка"
						options={professionalEducationTypes}
					/>
					<Validation errors={formik.errors.professional_education_type} />
				</div>
				<div className={styles.item}>
					<AsyncSelect label={'Город'} type={'cities'} name={'city'} selectValue={{value: formik.values.city, label: formik.values.city}} onSelectChange={(option)=> formik.setFieldValue('city',option?.value)}/>
					<Validation errors={formik.errors.city} />
				</div>
				<div className={styles.item}>
					<DatePicker label={'Дата начала практики'} value={formik.values.start_date} onChange={(date)=>formik.setFieldValue('start_date', date.toString())}/>
					<Validation errors={formik.errors.start_date} />
				</div>
				<div className={styles.item}>
					<TextField label={'Наименование учебного заведения'} onChange={formik.handleChange} id={'institution_name'} value={formik.values.institution_name}/>
					<Validation errors={formik.errors.institution_name} />
				</div>
				<div className={styles.item}>
					<DatePicker label={'Дата окончания практики'} value={formik.values.finish_date} onChange={(date)=>formik.setFieldValue('finish_date', date.toString())}/>
					<Validation errors={formik.errors.finish_date} />
				</div>
				<div className={styles.item}>
					<Select
						id={'course'}
						label={'Курс'}
						value={Courses.find((option) => option.value === formik.values.course)}
						onChange={(option) => formik.setFieldValue('course', option?.value)}
						placeholder="Выберите из списка"
						options={Courses}
					/>
					<Validation errors={formik.errors.course} />
				</div>
				<div className={styles.item}>
					<TextField label={'Количество студентов'} onChange={formik.handleChange} id={'student_count'} value={formik.values.student_count}/>
					<Validation errors={formik.errors.student_count} />
				</div>
				<div className={styles.item}>
					<Select
						id={'practice_type'}
						label={'Вид практики'}
						value={practiceTypes.find((option) => option.value === formik.values.practice_type)}
						onChange={(option) => formik.setFieldValue('practice_type', option?.value)}
						placeholder="Выберите из списка"
						options={practiceTypes}
					/>
					<Validation errors={formik.errors.practice_type} />
				</div>
				<div className={styles.item}>
					<TextField label={'ФИО отвечающего за практику'} onChange={formik.handleChange} id={'full_name_of_respondent'} value={formik.values.full_name_of_respondent}/>
					<Validation errors={formik.errors.full_name_of_respondent} />
				</div>
				<div className={styles.item}>
					<PhoneField label={'Номер телефона для связи'} value={formik.values.phone_of_respondent} onChange={(value)=>formik.setFieldValue('phone_of_respondent',value)} name={'phone_of_respondent'}/>
					<Validation errors={formik.errors.phone_of_respondent} />
				</div>
				<div className={styles.item}>
					<TextField label={'Почта для связи'} onChange={formik.handleChange} id={'email_of_respondent'} value={formik.values.email_of_respondent}/>
					<Validation errors={formik.errors.email_of_respondent} />
				</div>
			</form>
		</CustomPage>
	);
});

export default PracticeStudentUpdate;
