import { FC, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { useStore } from 'hooks/useStore';
import { UpdatePage } from 'components/Layouts';

import { FieldsType } from 'components/Logic/FieldByType/types';

const EventCardOstrovaUpdate: FC = observer(() => {
	const store = useStore();
	const { id } = useParams();
	const item = store.events.element;

	const fetchData = useCallback(async () => {
		if (id) {
			await store.events.fetchItem(id);
		}
	}, [id]);

	const eventsFields: FieldsType[] = [
		{
			type: 'file',
			label: 'Картинка',
			name: 'image',
			url: item.image_url
		},
		{
			type: 'textfield',
			label: 'Заголовок',
			name: 'name',
			value: item.name
		},
		{
			type: 'timeField',
			label: 'Время начала',
			name: 'start_time',
			value: item.start_time
		},
		{
			type: 'timeField',
			label: 'Время окончания',
			name: 'finish_time',
			value: item.finish_time
		},
		{
			type: 'datePicker',
			label: 'Дата проведения',
			name: 'date',
			value: item.date
		},
		{
			type: 'wysiwyg',
			label: 'Описание',
			name: 'description',
			value: item.description ?? ''
		},
	];

	return (
		<UpdatePage
			title={'Важные мероприятия / Изменение'}
			fields={eventsFields}
			store={store.eventsOstrova}
			fetchData={fetchData}
		/>
	);
});
export default EventCardOstrovaUpdate;
