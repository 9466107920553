import { FC } from 'react';
import { observer } from 'mobx-react-lite';

import { useStore } from 'hooks/useStore';
import { FieldsType } from 'components/Logic/FieldByType/types';
import { CreatePage } from 'components/Layouts';

const EventCardOstrovaCreate: FC = observer(() => {
	const store = useStore();

	const eventsFields: FieldsType[] = [
		{
			type: 'file',
			label: 'Картинка',
			name: 'image',
		},
		{
			type: 'textfield',
			label: 'Заголовок',
			name: 'name',
		},
		{
			type: 'timeField',
			label: 'Время начала',
			name: 'start_time',
		},
		{
			type: 'timeField',
			label: 'Время окончания',
			name: 'finish_time',
		},
		{
			type: 'datePicker',
			label: 'Дата проведения',
			name: 'date',
		},
		{
			type: 'wysiwyg',
			label: 'Описание',
			name: 'description',
		},
	];


	return (
		<CreatePage
			title={'Важные мероприятия / Создание'}
			fields={eventsFields}
			store={store.eventsOstrova}
		/>
	);
});

export default EventCardOstrovaCreate;
