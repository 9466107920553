export type IArticleRead = {
	id: number;
	name: string;
	content: string;
	description: string;
	image_url: string | null;
	type: string;
	created_at: string;
	updated_at: string;
};

export type IArticleCreate = {
	name: string;
	content: string;
	description: string;
	image_url: string | null;
	type: TArticleType;
};

export enum TArticleType {
	CAREER_ADVICE = 'Career advice',
	YOUTH_ISLANDS = 'Youth islands',
	YOUTH_PROJECTS = 'Youth projects',
}
