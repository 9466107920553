import { FC, useEffect } from 'react';
import { useFormik } from 'formik';
import { observer } from 'mobx-react-lite';
import { OutputData } from '@editorjs/editorjs';

import { useStore } from 'hooks/useStore';
import { Editor, TextField, Validation } from 'components/UI';
import { CustomPage } from 'components/Layouts';
import AsyncSelectBreadCrumbs from 'components/Logic/AsyncSelect/AsyncSelectBreadCrumbs';
import Map from '../components/Map/Map';
import PageButtons from './PageButtons/PageButtons';

import { TEditorType } from 'interfaces/entities/IEditor';
import { TSimpleEntity } from 'components/Logic/AsyncSelect/AsyncSelect/types';
import { TMarkCoords } from '../components/Mark/types';

import styles from './styles.module.scss';

const PavilionsOstrovaCreate: FC = observer(() => {
	const pavilionsStore = useStore().pavilionsOstrova;
	const backLink = location.pathname.slice(0, -7) + location.search;

	const formik = useFormik({
		initialValues: {
			name: '',
			description: null as null | OutputData,
			calendar_events: [] as TSimpleEntity[],
			event_ids : [] as TSimpleEntity[],
			x: null as number | null,
			y: null as number | null,
		},
		onSubmit: async (values) => {
			return pavilionsStore
				.createItem({
					name: values.name,
					description: values.description ? JSON.stringify(values.description) : null,
					event_ids: values.event_ids.map((item) => item.id),
					x: typeof values.x === 'number' ? values.x.toString() : null,
					y: typeof values.y === 'number' ? values.y.toString() : null,
				})
				.catch((data) => {
					const errors = data.errors;

					if ('calendar_event_ids' in errors) errors.calendar_events = errors.calendar_event_ids;
					if ('employer_profile_ids' in errors)
						errors.employer_profiles = errors.employer_profile_ids;

					formik.setErrors(data.errors);
					throw errors;
				});
		},
	});

	useEffect(() => {
		pavilionsStore.fetchItems('');
	}, []);

	const onChangeCurrentMark = (coords: TMarkCoords | null) => {
		formik.setFieldValue('x', coords ? coords.x : null);
		formik.setFieldValue('y', coords ? coords.y : null);
	};

	const currentMark: TMarkCoords | null =
		formik.values.x && formik.values.y ? { x: formik.values.x, y: formik.values.y } : null;

	const mapValidationError =
		formik.errors.y || formik.errors.x ? 'Положение на карте не может быть пустым.' : null;

	const pageButtons = <PageButtons formik={formik} type={'create'} />;

	return (
		<CustomPage title='Место проведения / Создание' bottomButtons={[pageButtons]} backLink={backLink}>
			<form onSubmit={formik.handleSubmit} className={styles.wrapper}>
				<div className={styles.item}>
					<TextField
						id='name'
						label='Название'
						value={formik.values.name}
						onChange={formik.handleChange}
					/>
					<Validation errors={formik.errors.name} />
				</div>

				<div></div>

				<div className={styles.item}>
					<AsyncSelectBreadCrumbs
						extra='Мероприятие может быть недоступно для выбора, если уже связано с другим павильоном'
						name='event_ids'
						type='events'
						label='Прикрепить мероприятие'
						placeholder='Выберите из списка'
						qualities={formik.values.event_ids}
						formikSetter={(items) => formik.setFieldValue('event_ids', items)}
					/>
					<Validation errors={formik.errors.event_ids} />
				</div>

				<div className={styles.mapWrapper}>
					<Map
						label='Положение на карте'
						currentMark={currentMark}
						setCurrentMark={onChangeCurrentMark}
					/>
					<Validation errors={mapValidationError} />
				</div>

				<div className={styles.editorWrapper}>
					<Editor
						label='Контент'
						tools={['list', 'image']}
						editorType={'event_place_wysiwyg' as TEditorType}
						value={formik.values.description}
						onChange={(data) => formik.setFieldValue('description', data)}
					/>
					<Validation errors={formik.errors.description} />
				</div>
			</form>
		</CustomPage>
	);
});

export default PavilionsOstrovaCreate;
