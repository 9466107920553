import { FC } from 'react';
import { observer } from 'mobx-react-lite';

import { useStore } from 'hooks/useStore';
import { CardsPage } from 'components/Layouts';
import EventCard from './EventCard';

const EventCardsOstrova: FC = observer(() => {
	const store = useStore();

	return <CardsPage title={'Важные мероприятия'} store={store.eventsOstrova} element={EventCard} />;
});

export default EventCardsOstrova;
